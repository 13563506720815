import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Alert, FormControl, MenuItem, Select } from '@mui/material';
import { getApiKey, getUrl, getUrlBasedOnEnv } from '../../app/api/agent';
import axios from 'axios';
import {
  getProduct,
  getUserData,
  getUserLeadScoreId,
  getUserNTQ,
  isUserParent,
  setStage,
  setUserData,
  setUserLeadScore,
  setUserNTQ,
} from '../../app/services/AuthService';
import StepsLayout from '../../app/common/StepsLayout';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import emailValidator from 'email-validator';
import './profile.css';
import {
  getNtqPercentage,
  getQuestion,
  getQuestions,
  loadQuestionsToSessionStorage,
  setNtqPercentage,
} from '../../app/services/QuestionsService';
import moment from 'moment/moment';
import YesNoRadioGroup from '../yes-no-radio-group/YesNoRadioGroup';

const Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [firstNameContact, setFirstNameContact] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameContact, setLastNameContact] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [phoneNumberContact, setPhoneNumberContact] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs('2014-08-18T21:11:54'));
  const [gender, setGender] = useState('');
  const [liveWith, setLiveWith] = useState([]);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [occupation, setOccupation] = useState('');
  const [hoursWorked, setHoursWorked] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [howLongFollowing, setHowLongFollowing] = useState('');
  const [afterHearing, setAfterHearing] = useState('');
  const [isParent, setIsParent] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(getUserData());
  const [isCaregiverOfPatientWithAutism, setIsCaregiverOfPatientWithAutism] =
    React.useState(user.isCaregiverOfPatientWithAutism);
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');
  const [error, setError] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsParent(isUserParent());
    loadQuestions();
    if (user) {
      setFirstName(user.firstName ? user.firstName : '');
      setLastName(user.lastName ? user.lastName : '');
      setDateOfBirth(dayjs(user.dateofBirth));
      setGender(user.gender ? user.gender : '');
      setHeight(user.height ? user.height : '');
      setWeight(user.weight ? user.weight : '');
      setMaritalStatus(user.maritalStatus ? user.maritalStatus : '');
      setOccupation(user.occupation ? user.occupation : '');
      setHoursWorked(user.hoursworkedperweek ? user.hoursworkedperweek : '');
      setLiveWith(user.live_with ? user.live_with : []);
      setFirstNameContact(user.ice?.first_name ? user.ice.first_name : '');
      setLastNameContact(user.ice?.last_name ? user.ice.last_name : '');
      setEmailContact(user.ice?.email ? user.ice.email : '');
      setPhoneNumberContact(user.ice?.phone ? user.ice.phone : '');
    }
  }, []);

  const loadQuestions = async () => {
    await loadQuestionsToSessionStorage();
    const status = getQuestion('my_current_employment_status_is_');
    setEmploymentStatus(status);
    const howLong = getQuestion(
      'how_long_have_you_been_following_dr__pompa_and_his_content_'
    );
    setHowLongFollowing(howLong);
    const afterHearing = getQuestion(
      'after_hearing_dr__pompa__how_confident_are_you_that_dr__pompa_has_the_answer_for_you_'
    );
    setAfterHearing(afterHearing);
  };

  const handleDateChange = (newValue) => {
    setDateOfBirth(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!howLongFollowing) {
      setHowLongFollowing('');
      setError(true);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorMessage(`All fields are required`);
      setMessageType('error');
      return;
    }
    if (!employmentStatus) {
      setEmploymentStatus('');
      setError(true);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorMessage(`The Employment Status field is required`);
      setMessageType('error');
      return;
    }
    if (!afterHearing) {
      setAfterHearing('');
      setError(true);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorMessage(`All fields are required`);
      setMessageType('error');
      return;
    }
    if (
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      hoursWorked.trim() === '' ||
      maritalStatus.trim() === '' ||
      gender.trim() === '' ||
      height.trim() === '' ||
      weight.trim() === '' ||
      employmentStatus.trim() === '' ||
      howLongFollowing.trim() === '' ||
      afterHearing.trim() === ''
    ) {
      setError(true);
      const emptyField = Object.entries({
        firstName,
        lastName,
        hoursWorked,
        maritalStatus,
        gender,
        weight,
        height,
        howLongFollowing,
        afterHearing,
        employmentStatus,
        // eslint-disable-next-line
      }).find(([key, value]) => value.trim() === '')[0];
      setErrorMessage(`All fields are required`);
      setMessageType('error');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }
    const currentYear = dayjs().year();
    if (dateOfBirth.year() === currentYear) {
      setErrorMessage('Date of birth cannot be in the current year');
      setMessageType('error');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }

    if (liveWith.length === 0) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setErrorMessage(`The Live width field is required`);
      setMessageType('error');
      return;
    }

    if (
      firstNameContact.trim() !== '' ||
      lastNameContact.trim() !== '' ||
      emailContact.trim() !== '' ||
      phoneNumberContact.trim() !== ''
    ) {
      setErrorContact(true);
      if (!emailValidator.validate(emailContact)) {
        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
        setErrorMessage('Invalid email address');
        setMessageType('error');
        return;
      }
      if (
        firstNameContact.trim() === '' ||
        lastNameContact.trim() === '' ||
        emailContact.trim() === '' ||
        phoneNumberContact.trim() === ''
      ) {
        if (firstNameContact.trim() === '') {
          setErrorMessage(`The field First Name Emergency Contact is required`);
          setMessageType('error');
        }
        if (lastNameContact.trim() === '') {
          setErrorMessage(`The field Last Name Emergency Contact is required`);
          setMessageType('error');
        }
        if (emailContact.trim() === '') {
          setErrorMessage(`The field Email Emergency Contact is required`);
          setMessageType('error');
        }
        if (phoneNumberContact.trim() === '') {
          setErrorMessage(
            `The field Phone Number Emergency Contact is required`
          );
          setMessageType('error');
        }
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (employmentStatus.trim() === '') {
      setErrorMessage(`The field Employment Status is required`);
      setMessageType('error');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }

    if (
      employmentStatus === 'Employed' ||
      employmentStatus === 'Self-Employed'
    ) {
      if (occupation.trim() === '') {
        setErrorMessage(`The field Occupation is required`);
        setMessageType('error');
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
        return;
      }
    }

    if (howLongFollowing === '---') {
      setErrorMessage(
        `The field How long have you been following Dr. Pompa and his content? is required`
      );
      setMessageType('error');
      return;
    }
    if (afterHearing === '---') {
      setErrorMessage(
        `The field After hearing Dr. Pompa, how confident are you that Dr. Pompa has the answer for you? is required`
      );
      setMessageType('error');
      return;
    }

    setErrorMessage(null);
    setUpdating(true);

    // Start HTTP Calls
    try {
      const userData = getUserData();
      const config = {
        url: getUrl() + '/profile',
        method: 'post',
        data: {
          email: userData.email,
          firstName: firstName,
          lastName: lastName,
          dateofBirth: dateOfBirth,
          weight: weight,
          height: height,
          maritalStatus: maritalStatus,
          gender: gender,
          occupation: occupation,
          hoursworkedperweek: hoursWorked,
          live_with: liveWith,
          isCaregiverOfPatientWithAutism,
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      };
      const response = await axios(config);
      setStage(3);
      setUserData(response.data.user, !userData.ice);

      // temp fix, we should improve this logic in the new frontend app
      if (!getQuestions()) {
        await loadQuestionsToSessionStorage();
      }
      const { email } = getUserData();

      //Create the NTQ with the HTTP Call
      const ntqUserData = getUserData();
      const questions = getQuestions();

      //NTQ Clean Up
      ntqUserData.dateofBirth = moment
        .utc(ntqUserData.dateofBirth)
        .startOf('day')
        .valueOf();
      delete ntqUserData.parent;
      delete ntqUserData.stage;
      delete ntqUserData.password;
      if (ntqUserData.ice) {
        const ice_id = ntqUserData.ice.hs_object_id;
        delete ntqUserData.ice;
        ntqUserData.ice = ice_id;
      }
      questions.email = ntqUserData.email;
      const live_with = ntqUserData.live_with.toString();
      ntqUserData.live_with = live_with;

      ntqUserData.ntq_percentage =
        getNtqPercentage() > 10 ? getNtqPercentage() : 10;

      questions.my_current_employment_status_is_ = employmentStatus;
      questions.marital_status = maritalStatus;
      questions.how_long_have_you_been_following_dr__pompa_and_his_content_ =
        howLongFollowing;
      questions.after_hearing_dr__pompa__how_confident_are_you_that_dr__pompa_has_the_answer_for_you_ =
        afterHearing;
      questions.is_caregiver_of_patient_with_autism =
        isCaregiverOfPatientWithAutism;

      let url = getUrlBasedOnEnv() + '/create-ntq';
      if (getUserNTQ()) {
        questions.ntq_id = getUserNTQ();
        questions.lead_score_id = getUserLeadScoreId();
        url = getUrlBasedOnEnv() + '/edit-ntq';
      }

      const ntqResponse = {
        url: url,
        method: 'post',
        data: { ...ntqUserData, ...questions },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      };

      const ntqRequest = await axios(ntqResponse);

      if (ntqRequest.data.ntq) {
        setUserNTQ(ntqRequest.data.ntq);
      }
      if (ntqRequest.data.lead_score) {
        setUserLeadScore(ntqRequest.data.lead_score);
      }

      if (ntqRequest.data.ntq_percentage) {
        setNtqPercentage(ntqRequest.data.ntq_percentage);
      }

      if (!emailContact) {
        setErrorMessage('Success Updating User....');
        setMessageType('success');
        setTimeout(() => {
          setUpdating(false);
          navigate('/nta');
        }, 2000);
        return;
      }

      const config2 = {
        url: getUrl() + '/ice',
        method: 'post',
        data: {
          userEmail: email,
          email: emailContact,
          first_name: firstNameContact,
          last_name: lastNameContact,
          phone: phoneNumberContact,
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      };

      if (userData.ice) {
        config2.url = getUrl() + '/edit-ice';
        config2.data.ice = userData.ice.hs_object_id;
      }

      const response2 = await axios(config2);

      const user = getUserData();
      if (response2.data.ice) {
        user.ice = response2.data.ice.properties;
      } else {
        user.ice = config2.data;
        user.ice.hs_object_id = config2.data.ice;
      }
      user.isCaregiverOfPatientWithAutism = isCaregiverOfPatientWithAutism;
      setUserData(user);
      setErrorMessage('Success Updating User....');
      setMessageType('success');
      setTimeout(() => {
        setUpdating(false);
        navigate('/nta');
      }, 2000);
    } catch (error) {
      setMessageType('error');
      console.error('error in profile', error);
      setUpdating(false);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'There has been a problem with the request, please try again later'
        );
      }
    }
  };

  return (
    <StepsLayout>
      <Box display="flex">
        {getProduct() === 'double' ? (
          <img src="/2-icon.png" alt="Logo" style={{ height: '84px' }} />
        ) : (
          <>
            {isParent ? (
              <img src="/2-icon.png" alt="Logo" style={{ height: '84px' }} />
            ) : (
              <img src="/1-icon.png" alt="Logo" style={{ height: '84px' }} />
            )}
          </>
        )}
        <Typography component="h1" variant="h3" className="steps-header">
          Edit Personal Info
        </Typography>
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: 'white',
            padding: '50px 30px 30px 30px',
            borderRadius: '10px',
          }}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                First Name
              </Typography>
              <TextField
                error={error && firstName.trim() === ''}
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                autoFocus
                onChange={(event) => setFirstName(event.target.value)}
                value={firstName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Last Name
              </Typography>
              <TextField
                required
                error={error && lastName.trim() === ''}
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                onChange={(event) => setLastName(event.target.value)}
                value={lastName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Date Of Birth
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={dateOfBirth}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <FormControl fullWidth>
                <Typography variant="p" className="input-label">
                  Gender
                </Typography>
                <Select
                  value={gender}
                  error={error && gender.trim() === ''}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => setGender(event.target.value)}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Height
              </Typography>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl div-wrapper-format-input">
                  <PatternFormat
                    onChange={(event) => setHeight(event.target.value)}
                    value={height}
                    format='#"##"'
                    className="MuiInputBase-input MuiOutlinedInput-input div-input-format-input"
                  />
                  <fieldset
                    aria-hidden="true"
                    style={{
                      borderColor:
                        error && height.trim() === ''
                          ? '#d32f2f'
                          : 'rgba(0, 0, 0, 0.23)',
                    }}
                    className="MuiOutlinedInput-notchedOutline div-input-format-input-fieldset"
                  >
                    <legend className="css-ihdtdm">
                      <span className="notranslate">​</span>
                    </legend>
                  </fieldset>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Weight
              </Typography>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl div-wrapper-format-input">
                  <PatternFormat
                    onChange={(event) => setWeight(event.target.value)}
                    value={weight}
                    format="### lbs"
                    className="MuiInputBase-input MuiOutlinedInput-input div-input-format-input"
                  />
                  <fieldset
                    aria-hidden="true"
                    style={{
                      borderColor:
                        error && weight.trim() === ''
                          ? '#d32f2f'
                          : 'rgba(0, 0, 0, 0.23)',
                    }}
                    className="MuiOutlinedInput-notchedOutline div-input-format-input-fieldset"
                  >
                    <legend className="css-ihdtdm">
                      <span className="notranslate">​</span>
                    </legend>
                  </fieldset>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <FormControl fullWidth>
                <Typography variant="p" className="input-label">
                  Marital Status
                </Typography>
                <Select
                  value={maritalStatus}
                  error={error && maritalStatus.trim() === ''}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => setMaritalStatus(event.target.value)}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <FormControl fullWidth>
                <Typography variant="p" className="input-label">
                  Live With
                </Typography>
                <Select
                  value={liveWith}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  error={error && liveWith.length === 0}
                  onChange={(event) => setLiveWith(event.target.value)}
                >
                  <MenuItem value="Spouse">Spouse</MenuItem>
                  <MenuItem value="Partner">Partner</MenuItem>
                  <MenuItem value="Parents">Parents</MenuItem>
                  <MenuItem value="Children">Children</MenuItem>
                  <MenuItem value="Friends">Friends</MenuItem>
                  <MenuItem value="Alone">Alone</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                My current employment status is:
              </Typography>
              <Select
                value={employmentStatus}
                labelId="my_current_employment_status_is_"
                id="my_current_employment_status_is_"
                error={error && !employmentStatus}
                onChange={(event) => setEmploymentStatus(event.target.value)}
              >
                <MenuItem value="Employed">Employed</MenuItem>
                <MenuItem value="Unemployed">Unemployed</MenuItem>
                <MenuItem value="Self employed">Self employed</MenuItem>
                <MenuItem value="Disability">Disability</MenuItem>
                <MenuItem value="Retired">Retired</MenuItem>
              </Select>
            </Grid>
            {(employmentStatus === 'Employed' ||
              employmentStatus === 'Self employed') && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="p" className="input-label">
                  Occupation
                </Typography>
                <TextField
                  required
                  error={error && occupation.trim() === ''}
                  value={occupation}
                  fullWidth
                  id="occupation"
                  name="occupation"
                  type="text"
                  onChange={(event) => setOccupation(event.target.value)}
                />
              </Grid>
            )}
            {(employmentStatus === 'Unemployed' ||
              employmentStatus === 'Disability' ||
              employmentStatus === 'Retired') && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="p" className="input-label">
                  Former Occupation
                </Typography>
                <TextField
                  required
                  error={error && occupation.trim() === ''}
                  value={occupation}
                  fullWidth
                  id="occupation"
                  name="occupation"
                  type="text"
                  onChange={(event) => setOccupation(event.target.value)}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Hours Worked Per Week
              </Typography>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl div-wrapper-format-input">
                  <PatternFormat
                    onChange={(event) => setHoursWorked(event.target.value)}
                    value={hoursWorked}
                    format="## hours"
                    className="MuiInputBase-input MuiOutlinedInput-input div-input-format-input"
                  />
                  <fieldset
                    aria-hidden="true"
                    style={{
                      borderColor:
                        error && hoursWorked.trim() === ''
                          ? '#d32f2f'
                          : 'rgba(0, 0, 0, 0.23)',
                    }}
                    className="MuiOutlinedInput-notchedOutline div-input-format-input-fieldset"
                  >
                    <legend className="css-ihdtdm">
                      <span className="notranslate">​</span>
                    </legend>
                  </fieldset>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                How long have you been following Dr. Pompa and his content?
              </Typography>
              <Select
                value={howLongFollowing}
                labelId="how_long_have_you_been_following_dr__pompa_and_his_content_"
                id="how_long_have_you_been_following_dr__pompa_and_his_content_"
                error={
                  (error && howLongFollowing.length === 0) ||
                  howLongFollowing === '---'
                }
                onChange={(event) => setHowLongFollowing(event.target.value)}
              >
                <MenuItem value="---">---- Select Option ----</MenuItem>
                <MenuItem value="I recently discovered Dr Pompa (30 days or less)">
                  I recently discovered Dr. Pompa (30 days or less)
                </MenuItem>
                <MenuItem value="Ive seen him before, and recently started watching some of his content">
                  I’ve seen him before, and recently started watching some of
                  his content.
                </MenuItem>
                <MenuItem value="I have been a long time follower of Dr Pompa and watch nearly everything he puts out there">
                  I have been a long time follower of Dr. Pompa and watch nearly
                  everything he puts out there.
                </MenuItem>
              </Select>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                After hearing Dr. Pompa, how confident are you that Dr. Pompa
                has the answer for you?
              </Typography>
              <Select
                value={afterHearing}
                labelId="after_hearing_dr__pompa__how_confident_are_you_that_dr__pompa_has_the_answer_for_you_"
                id="after_hearing_dr__pompa__how_confident_are_you_that_dr__pompa_has_the_answer_for_you_"
                error={(error && !afterHearing) || afterHearing === '---'}
                onChange={(event) => setAfterHearing(event.target.value)}
              >
                <MenuItem value="---">---- Select Option ----</MenuItem>
                <MenuItem value="I am confident that Dr Pompa has the answers for me">
                  I am confident that Dr. Pompa has the answers for me.
                </MenuItem>
                <MenuItem value="I dont know enough about Dr Pompa to answer">
                  I don’t know enough about Dr. Pompa to answer.
                </MenuItem>
                <MenuItem value="No, I dont think Dr Pompa can teach me anything I havent tried">
                  No, I don’t think Dr. Pompa can teach me anything I haven’t
                  tried.
                </MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                If you are the caregiver / proxy AND completing this
                questionnaire for the client: Is the individual diagnosed with
                autism, or is there suspicion of autism or another neurological
                disorder?
              </Typography>
              <YesNoRadioGroup
                defaultValue={isCaregiverOfPatientWithAutism}
                onChange={(value) => {
                  setIsCaregiverOfPatientWithAutism(value);
                }}
              />
              <Typography variant="p" className="">
                Other neurological disorders include Asperger's, Pervasive
                Developmental Disorder, Sensory Disorder, OCD, Anxiety,
                Tourette, PANS/PANDAS, Basal Ganglia Encephalitis, Lyme &
                Co-infections, Intellectual Disability
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="p"
                className="input-label"
                sx={{ marginTop: '30px', fontSize: '22px !important' }}
              >
                Emergency Contact Information
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                First Name
              </Typography>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                error={errorContact && firstNameContact.trim() === ''}
                fullWidth
                id="firstName"
                onChange={(event) => setFirstNameContact(event.target.value)}
                value={firstNameContact}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Last Name
              </Typography>
              <TextField
                required
                fullWidth
                id="lastName"
                name="lastName"
                error={errorContact && lastNameContact.trim() === ''}
                autoComplete="family-name"
                onChange={(event) => setLastNameContact(event.target.value)}
                value={lastNameContact}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Email
              </Typography>
              <TextField
                required
                fullWidth
                error={errorContact && emailContact.trim() === ''}
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                onChange={(event) => setEmailContact(event.target.value)}
                value={emailContact}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="p" className="input-label">
                Phone Number
              </Typography>
              <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl">
                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl div-wrapper-format-input">
                  <PatternFormat
                    onChange={(event) =>
                      setPhoneNumberContact(event.target.value)
                    }
                    value={phoneNumberContact}
                    format="+1 (###) ### ####"
                    className="MuiInputBase-input MuiOutlinedInput-input div-input-format-input"
                  />
                  <fieldset
                    aria-hidden="true"
                    style={{
                      borderColor:
                        errorContact && phoneNumberContact.trim() === ''
                          ? '#d32f2f'
                          : 'rgba(0, 0, 0, 0.23)',
                    }}
                    className="MuiOutlinedInput-notchedOutline div-input-format-input-fieldset"
                  >
                    <legend className="css-ihdtdm">
                      <span className="notranslate">​</span>
                    </legend>
                  </fieldset>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className="profile-error" sx={{ marginTop: '10px' }}>
          {errorMessage && <Alert severity={messageType}>{errorMessage}</Alert>}
        </Box>
        <Button
          disabled={updating}
          type="submit"
          fullWidth
          style={{
            float: 'right',
            width: '250px',
            fontSize: '17px',
            marginTop: '35px',
            borderRadius: '9px',
            backgroundColor: '#85b4cd',
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          NEXT
        </Button>
      </Box>
    </StepsLayout>
  );
};

export default Profile;
